<template>
  <div>
    <!--begin::User-->
    <div>

      <div class="pb-5 d-flex justify-content-between">
        <h3 class="card-label">
          {{ $t('customers_status.customers_status') }}
          <span class="text-muted pt-2 font-size-sm d-block"></span>
        </h3>
        <custom-export-data v-if="$can('customer-status.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('appointments.appointments_management')"></custom-export-data>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">

          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('customers_status.statuses') }}</h6>
              <button type="button" class="btn btn-primary mr-2" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
              <thead>
              <tr class="text-center">
                <th width="40%">{{ $t('customers_status.customer') }}</th>
                <th width="10%">{{ $t('customers_status.is_default') }}</th>
                <th width="10%">{{ $t('customers_status.is_active') }}</th>
                <th width="10%">{{ $t('customers_status.color') }}</th>
                <th width="20%" class="text-center">

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cu, index) in data_customers" :key="index">
                <td>
                  <input type="text" v-model="cu.customer" class="form-control" :placeholder="$t('customers_status.customer')"/>
                  <span v-if="validation && validation['customers.'+index+'.customer']" class="fv-plugins-message-container invalid-feedback">
                                         {{ validation['customers.' + index + '.customer'][0] }}
                                        </span>
                </td>
                <td class="text-center">
                  <b-form-checkbox size="lg" @change="changeDefaultStatus(index, cu.is_default)" v-model="cu.is_default" :name="'check-button'+cu.id" switch :key="cu.id"></b-form-checkbox>

                </td>
                <td class="text-center">
                  <b-form-checkbox size="lg" @change="changeStatus(cu.id, cu.is_active)" v-model="cu.is_active" :name="'check-button'+cu.id" switch :key="cu.id"></b-form-checkbox>
                </td>
                <td>
                  <input type="color" v-model="cu.color" class="form-control" :placeholder="$t('customers_status.color')"/>
                  <span v-if="validation && validation['customers.'+index+'.color']" class="fv-plugins-message-container invalid-feedback">
                                         {{ validation['customers.' + index + '.color'][0] }}
                                        </span>
                </td>

                <td class="text-center">
                  <v-icon class="text-danger" @click="cu.id ? deleteItem(cu) : deleteFromRepeater(index)">mdi-delete</v-icon>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-customers-status",
  data() {
    return {
      mainRoute: 'customers/customers-status',
      subMainRoute: 'customers/customer-status',
      mainRouteDependency: 'base/dependency',
      customers: [],

      dataList: [],
      idEdit: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      repeater_customers: {id: null, customer: null, is_default: false, is_active: false, color: "#1d6853"},
      data_customers: [],
    };
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('customers_status.customer')] = 'customer';
      fields[this.$t('customers_status.color')] = 'color';

      return fields;
    },
  },
  methods: {
    // save() {
    //     if (this.isEditing) {
    //         this.update();
    //     } else {
    //         this.create();
    //     }
    // },

    save() {

      ApiService.post(this.mainRoute, {
        customers: this.data_customers,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    //
    // update() {
    //
    //     ApiService.put(this.mainRoute + '/' + this.idEdit, {
    //         customers: this.data_customers,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    async getData() {
      await ApiService.get(this.mainRoute).then((response) => {
        this.isEditing = true;
        this.data_customers = response.data.data;
        this.dataList = response.data.data;
        if (response.data.data.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getCustomers() {
      ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
        this.customers = response.data.data;
      });
    },

    addToRepeater() {
      this.data_customers.unshift(this.repeater_customers);
      this.repeater_customers = {id: null, customer: null, is_default: false, is_active: false, color: "#1d6853"};
    },
    deleteFromRepeater(index) {
      this.data_customers.splice(index, 1);
    },

    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getData();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    changeStatus(id, status) {
      if (id) {
        ApiService.patch(this.subMainRoute + '/change-status/' + id, {
          is_active: (status ? '1' : '0'),
        }).then(response => {
          this.$successAlert(response.data.message);
        }).catch(error => {
          this.$errorAlert(error);
        });
      }

    },
    changeDefaultStatus(index, status) {
      // if (id){
      //     ApiService.patch(this.subMainRoute + '/change-status/' + id, {
      //         is_default: (status ? '1' : '0'),
      //     }).then(response => {
      //         this.$successAlert(response.data.message);
      //     }).catch(error => {
      //         this.$errorAlert(error);
      //     });
      // }
      this.data_customers = this.data_customers.map((row) => {
        row.is_default = false;
        return row;
      });
      this.data_customers[index].is_default = status;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customers_status")}]);
    // this.getCustomers();

    let promise = this.getData();
    let that = this;
    Promise.all([promise]).then(() => {
      if (that.data_customers.length <= 0) {
        that.addToRepeater();
      }
    });


  },
};
</script>


